import React, { useContext } from "react"
import get from 'lodash/get'
import { graphql } from "gatsby"
import parse from 'html-react-parser';
import { getCurrentLangKey } from 'ptz-i18n';
import styled from 'styled-components'

import Layout from "../components/layout"
import HeaderSection from '../components/LandingPagesDark/HeaderSection/HeaderSection'
import SEO from "../components/seo"
import RevealOpacity from '../components/Animation/RevealOpacity'

import VimeoVideo from "../components/Team/VimeoVideo";

import { media } from "../lib/style-utils";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 0;
    width: 95vw;
    ${media.desktop`
      width: 85vw;
    `}
`;

const NieuwTitle = styled.h1`
text-align: left !important;
font-size: 25px;
${media.phoneS`
font-size: 30px;
`}
${media.desktop`
  font-size: max(3.5vw, 41px);
`}
`;

const NieuwText = styled.div`
font-size: 20px;
line-height: 25px;
${media.phoneS`
font-size: 25px;
line-height: 32px;
`}
margin-bottom: 3rem;
${media.desktop`
  font-size: 35px;
  line-height: 46px;
  margin-bottom: 4rem;
`}
font-weight: 600;

& p {
  margin-bottom: 0rem;
}
`;

const VideoGrid = styled.div`
display: flex;
flex-direction: column;
${media.tablet`
flex-direction: row;
flex-flow: wrap;
`}
width: inherit;
margin-bottom: 6rem;
margin-left: auto;
margin-right: auto;
`;

const Team = props => {

  const url = props.location.pathname;
  const { langs, defaultLangKey } = props.data.site.siteMetadata.languages;

  let langKey = getCurrentLangKey(langs, defaultLangKey, url);
  let homeLink = `/${langKey}/`

  const data = get(props, 'data', [])
  const seoData = get(props, 'data.seo.nodes[0].seo', [])
  const pageData = get(props, 'data.pageData.nodes[0]', [])

  let seoObject = {
    title: seoData.title,
    description: seoData.description,
    lang: 'en',
    image: seoData.social.facebook.image && seoData.social.facebook.image.url,
    imageTwitter: seoData.social.twitter.image && seoData.social.twitter.image.url,
    robots: seoData.advanced.robots && seoData.advanced.robots
  }

  // slugs for translation
  const slugs = {
    slugNl: props.data.translatedUrl.nodes[0].slug,
    slugEn: props.data.pageData.nodes[0].slug,
  }

  return (
    <Layout form={true} data={props.data.site} location={props.location} slugs={slugs}>
      <SEO {...seoObject} />
      {/* <Container>
            <HeaderSection data={data} url={homeLink} pageData={pageData}/>
      </Container> */}
      {/* <Container>
        <RevealOpacity>
          <NieuwTitle>Superkraft presents:<br/>{data.pageData.nodes[0].nieuwTitle}</NieuwTitle>
          <NieuwText>{parse(data.pageData.nodes[0].nieuwText)}</NieuwText>
          <VideoGrid>
            {data.pageData.nodes[0].nieuwjaarsbrievenVideos.map((video, index) => {
              return (
                <VimeoVideo key={index} name={video.naam} func={video.functieTitel} VimeoID={video.vimeoId} VimeoMobileID={video.vimeoMobileId} image={video.imagePlaceholder[0].url} />
              )
            })}
          </VideoGrid>
        </RevealOpacity>
      </Container> */}
    </Layout>
  )
}

export default Team;

export const query = graphql`
{
  site {
    siteMetadata {
      languages {
        defaultLangKey
        langs
      }
    }
  }
  pageData:allCraftTeamTeamEntry(filter: {siteId: {eq: 1}}) {
    nodes {
      id
      title
      uri
      slug
      nieuwTitle
      nieuwText
      nieuwjaarsbrievenVideos {
        ... on craft_nieuwjaarsbrievenVideos_brief_BlockType {
          id
          naam
          functieTitel
          vimeoId
          vimeoMobileId
          imagePlaceholder {
            ... on craft_superkraft_Asset {
              id
              url
            }
          }
        }
      }
    }
  }
  translatedUrl:allCraftTeamTeamEntry(filter: {siteId: {eq: 2}}){
		nodes {
			slug
    }
  }
  seo:allCraftTeamTeamEntry(filter: {siteId: {eq: 1}}){
		nodes{
      title
      slug
      seo {
        title
        description
        social {
          facebook {
            image {
              url
            }
          }
          twitter {
            image {
              url
            }
          }
        }
        advanced {
          robots
        }
      }
    }
  }
}
`